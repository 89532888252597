<template>
	<ManageYourAccountTemplate full-width>
		<BRow>
			<BCol class="text-center" data-test-automation-id="manageYourAccountTitle">
				<h1>{{ $t('title', { name: member.firstName }) }}</h1>
			</BCol>
		</BRow>
		<BRow>
			<BCol>
				<div class="d-md-flex justify-content-center">
					<div class="text-center">
						<span class="id-label">{{ $t('certificateId') }} </span>
						{{ member.certificateId }}
					</div>
					<div class="text-center">
						<span class="id-label">{{ $t('policyNumber') }} </span>
						{{ member.policyId }}
					</div>
				</div>
			</BCol>
		</BRow>
		<BRow class="my-4">
			<BCol cols="12" md="6" class="mb-3">
				<AccountInformationLinkList />
			</BCol>
			<BCol cols="12" md="6" class="mb-3">
				<PaymentInformationLinkList />
			</BCol>
		</BRow>
	</ManageYourAccountTemplate>
</template>

<script>
import BreadcrumbsManager from '@/mixins/BreadcrumbsManager';
import { BRow, BCol } from 'bootstrap-vue';
import AccountInformationLinkList from '@/components/manage-your-account/AccountInformationLinkList.vue';
import PaymentInformationLinkList from '@/components/manage-your-account/PaymentInformationLinkList.vue';
import MemberCardInfo from '@/models/MemberCardInfo';
import * as Routes from '@/constants/Routes';
import ManageYourAccountTemplate from './ManageYourAccountTemplate.vue';

export default {
	name: 'ManageYourAccountPage',
	components: {
		BRow,
		BCol,
		AccountInformationLinkList,
		PaymentInformationLinkList,
		ManageYourAccountTemplate
	},
	mixins: [BreadcrumbsManager],

	data() {
		return {
			member: {
				firstName: '',
				certificateId: '',
				policyId: ''
			}
		};
	},

	computed: {
		locale() {
			return this.$root.$i18n ? this.$root.$i18n.locale : 'en';
		}
	},

	created: async function () {
		// Set the path for the BreadCrumbs Manager.
		this.setBreadcrumbPath(
			[
				{ text: this.$t('breadcrumb.home', 'en'), to: { name: Routes.DASHBOARD } },
				{ text: this.$t('breadcrumb.manageYourAccount', 'en') }
			],
			[
				{ text: this.$t('breadcrumb.home', 'fr'), to: { name: Routes.DASHBOARD } },
				{ text: this.$t('breadcrumb.manageYourAccount', 'fr') }
			]
		);

		if (sessionStorage.getItem('email') && !this.member?.policyId) {
			this.$store.dispatch('updateLoading', true);
			this.member = await MemberCardInfo.getMemberDetail(
				sessionStorage.getItem('email'),
				sessionStorage.getItem('apiToken'),
				this.locale
			);
		}
		this.$store.dispatch('updateLoading', false);
	},

	updated: async function () {
		this.$store.dispatch('updateLoading', false);
	}
};
</script>

<style lang="scss" scoped>
.id-label {
	font-family: $font-family-headline;
}
.d-md-flex {
	gap: 30px;
}
</style>

<i18n>
{
  "en": {
    "breadcrumb": {
      "home": "Home",
      "manageYourAccount": "Manage your account"
    },
    "title": "Welcome, {name}",
    "certificateId": "ID:",
    "policyNumber": "Policy:"
  },
  "fr": {
    "breadcrumb": {
      "home": "Accueil",
      "manageYourAccount": "Gérer votre compte"
    },
    "title": "Bienvenue, {name}",
    "certificateId": "Nº d’identification :",
    "policyNumber": "Nº de police :"
  }
}
</i18n>
