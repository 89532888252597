<!--
  Display a list of links related to member account information.
-->

<template>
	<LinkList
		:header-title="$t('header.accountInformation')"
		:header-text="$t('header.instructions')"
		automation-id="accountInformationLinkList"
	>
		<LinkListItem
			v-for="(link, index) in links"
			:key="index"
			:title="link.title"
			:to="link.to"
			:automation-id="`accountInformationItem${index}`"
		></LinkListItem>
	</LinkList>
</template>
<script>
import * as Routes from '@/constants/Routes.js';
import LinkList from '../common/LinkList.vue';
import LinkListItem from '../common/LinkListItem.vue';

export default {
	name: 'AccountInformationCard',
	components: {
		LinkList,
		LinkListItem
	},

	computed: {
		/** To add more links in the list, simply add more objects to the array. */
		links() {
			return [
				{
					title: this.$t('title.personalInformation'),
					to: { name: Routes.PERSONAL_INFORMATION }
				},
				{
					title: this.$t('title.peopleOnYourPlan'),
					to: { name: Routes.PEOPLE_ON_YOUR_PLAN }
				},
				{
					title: this.$t('title.memberCard'),
					to: { name: Routes.MEMBER_CARD }
				},
				{
					title: this.$t('title.changePassword'),
					to: { name: Routes.CHANGE_PASSWORD }
				},
				{
					title: this.$t('title.termsAndCondition'),
					to: { name: Routes.PRIVACY_AND_TERMS }
				}
			];
		}
	}
};
</script>

<i18n>
{
  "en": {
    "header": {
      "accountInformation": "Account Information",
      "instructions": "Keep your address and contact information up to date to make sure you never miss important updates."
    },
    "title": {
      "personalInformation": "Personal Information",
      "peopleOnYourPlan": "People on your plan",
      "memberCard": "Member card",
      "changePassword": "Change your password",
      "termsAndCondition": "Terms and conditions of use"
    }
  },
  "fr": {
    "header": {
      "accountInformation": "Renseignements sur le compte",
      "instructions": "Mettez à jour votre adresse et vos coordonnées pour vous assurer de ne jamais manquer de nouvelles importantes. "
    },
    "title": {
      "personalInformation": "Renseignements personnels",
      "peopleOnYourPlan": "Personnes assurées",
      "memberCard": "Carte d’identification",
      "changePassword": "Modifier votre mot de passe",
      "termsAndCondition": "Conditions d’utilisation du site"
    }
  }
}
</i18n>
