<!--
  Parent for all Manage Your Account pages

  Having this parent/child relationship allows us to display slugs such as ../manage-your-account/personal-information
  in the URL.  This matches nicely with the site structure and is SEO friendly.
-->
<template>
	<router-view></router-view>
</template>

<script>
export default {
	name: 'ManageYourAccount'
};
</script>
