<!--
  Display a list of links related to member payment information.
-->

<template>
	<LinkList
		:header-title="$t('header.paymentInformation')"
		automation-id="paymentInformationLinkList"
	>
		<LinkListItem
			v-for="(link, index) in availableLinks"
			:key="index"
			:title="link.title"
			:to="link.to"
			:automation-id="`paymentInformationItem${index}`"
		></LinkListItem>
	</LinkList>
</template>

<script>
import * as Routes from '@/constants/Routes.js';
import LinkList from '../common/LinkList.vue';
import LinkListItem from '../common/LinkListItem.vue';

export default {
	name: 'PaymentInformationCard',
	components: {
		LinkList,
		LinkListItem
	},

	data() {
		return {
			securityPolicy: {}
		};
	},

	computed: {
		/** To add more links in the list, simply add more objects to the array. */
		links() {
			return [
				{
					title: this.$t('title.directDeposit'),
					to: { name: Routes.DIRECT_DEPOSIT },
					canAccess: true
				},
				{
					title: this.$t('title.premiumPaymentMethod'),
					to: { name: Routes.PREMIUM_PAYMENT_METHOD },
					canAccess: this.securityPolicy.premiumPayment
				},
				{
					title: this.$t('title.premiumStatements'),
					to: { name: Routes.PREMIUM_STATEMENTS },
					canAccess: this.securityPolicy.premiums
				}
			];
		},

		/** Only show links the user has access to.  */
		availableLinks() {
			return this.links.filter((link) => link.canAccess);
		}
	},

	created: async function () {
		this.securityPolicy = JSON.parse(await sessionStorage.getItem('securityPolicy'));
	}
};
</script>

<i18n>
{
  "en": {
    "header": {
      "paymentInformation": "Payment Information"
    },
    "title": {
      "directDeposit": "Edit direct deposit",
      "premiumPaymentMethod": "Review and update your premium payment method",
      "premiumStatements": "Review and print your premium statements"
    }
  },
  "fr": {
    "header": {
      "paymentInformation": "Renseignements financiers"
    },
    "title": {
      "directDeposit": "Modifier le dépôt direct",
      "premiumPaymentMethod": "Consulter et modifier le mode de paiement des primes",
      "premiumStatements": "Consulter et imprimer vos relevés de primes"
    }
  }
}
</i18n>
